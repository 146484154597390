import React, { useRef } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Portfolio from './components/Portfolio';
import Footer from './components/Footer';
import About from './components/About';
import Contact from './components/Contact';

function App() {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const projectsRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <div className="relative min-h-full overflow-hidden bg-main-text-colour">
      <div className="relative m-4 rounded-3xl bg-main-bg-colour p-6">
        <h1 className="fixed left-1/2 -z-10 rotate-90 text-white">:)</h1>
        <Navbar refs={{ homeRef, aboutRef, projectsRef, contactRef }} />
        <div id="Home" ref={homeRef}>
          <Hero />
        </div>
      </div>
      <div id="About" ref={aboutRef}>
        <About />
      </div>
      <div id="Projects" ref={projectsRef}>
        <Portfolio />
      </div>
      <div id="Contact" ref={contactRef}>
        <Contact />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
