import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';

import MainImage from '../assets/me.jpeg';
import Carousel from './Carousel';

export default function Hero() {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const greetings = [
    'Hello! My name is,', // English
    'Hallo ich heiße,', // German
    'Bonjour mon nom est,', // French
    'Hola mi nombre es,', // Spanish
    'Bula! Na yacaqu o,', // Fijian
    'Γεια σας το όνομά μου είναι,', // Greek
    'Hej mitt namn är,', // Swedish
    'Dobrý den, jmenuji se,', // Czech
    'Ciao mi chiamo,', // Italian
  ];
  const greetingCount = greetings.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((currentWordIndex + 1) % greetingCount);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentWordIndex, greetingCount]);

  return (
    <div>
      <div className="flex h-screen items-center justify-center">
        <div className="items-cener flex flex-row justify-center">
          <div className="flex flex-col justify-center">
            <motion.div
              className="text-center font-PPEditorial text-2xl text-main-text-colour"
              key={currentWordIndex}
              initial={{ opacity: 1, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.1 }}
            >
              {greetings[currentWordIndex]}
            </motion.div>
            <div className="pt-4 text-center font-PPEditorial text-9xl italic text-main-text-colour">
              Mitchel Mckee
            </div>
            <div className="text-center font-PPEditorial text-main-text-colour">
              Full stack developer based in the UK
            </div>
          </div>
          {/* <div className="flex justify-center items-center">
            <img
              src={MainImage}
              className="size-96 rounded-full border-main-text-colour border-8"
              alt="Mitchel Mckee"
            />
          </div> */}
        </div>
      </div>
      <Carousel />
    </div>
  );
}
