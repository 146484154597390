import { FaGithub, FaPhone } from 'react-icons/fa';
import { MdOutlineEmail } from 'react-icons/md';

export default function Contact() {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <h1 className="mb-8 text-center font-PPEditorial italic text-main-bg-colour md:text-8xl">
        Get In Touch
      </h1>
      <div className="flex flex-col items-center space-y-4 font-PPEditorial text-3xl text-main-bg-colour">
        <div className="flex items-center space-x-2">
          <a
            href="https://github.com/mitchelmckee"
            className="flex items-center space-x-2 text-main-bg-colour"
          >
            <FaGithub className="text-main-bg-colour" />
            <span>mitchelmckee</span>
          </a>
        </div>
        <div className="flex items-center space-x-2">
          <a
            href="mailto:mckeemitchel@gmail.com"
            className="flex items-center space-x-2 text-main-bg-colour"
          >
            <MdOutlineEmail className="text-main-bg-colour" />
            <span>mckeemitchel@gmail.com</span>
          </a>
        </div>
        <div className="flex items-center space-x-2">
          <FaPhone className="text-main-bg-colour" />
          <span>+44 7592 786 908</span>
        </div>
      </div>
    </div>
  );
}
