import React, { useRef, useEffect } from 'react';

import reactLogo from '../assets/React.svg';
import tailwindLogo from '../assets/TailwindCSS.svg';
import pythonLogo from '../assets/Python.svg';
import figmaLogo from '../assets/Figma.svg';
import javaLogo from '../assets/Java.svg';
import photoshopLogo from '../assets/Photoshop.svg';
import swiftLogo from '../assets/Swift.svg';
import cppLogo from '../assets/CPP.svg';
import cloudflarePagesLogo from '../assets/Cloudflare.svg';

const images = [
  reactLogo,
  tailwindLogo,
  pythonLogo,
  figmaLogo,
  javaLogo,
  photoshopLogo,
  swiftLogo,
  cppLogo,
  cloudflarePagesLogo,
];

const Carousel = () => {
  const trackRef = useRef(null);

  useEffect(() => {
    const track = trackRef.current;
    const imageWidth = 80 + 10; // Image width plus gap
    const numImages = Math.ceil(window.innerWidth / imageWidth);

    // Duplicate images enough to fill the viewport twice
    const duplicatedImages = Array(numImages * 2)
      .fill(images)
      .flat();

    // Set track width to accommodate all images
    track.style.width = `${duplicatedImages.length * imageWidth}px`;

    // Add duplicated images to track
    track.innerHTML = duplicatedImages
      .map(
        (image, index) =>
          `<div class="w-[80px] h-[80px] mr-2 flex-shrink-0">
         <img src="${image}" alt="Slide ${index}" className="h-auto" />
       </div>`,
      )
      .join('');
  });

  return (
    <div className="relative overflow-hidden bg-main-bg-colour">
      <div ref={trackRef} className="flex animate-scroll"></div>
      <div className="absolute left-0 top-0 h-full w-96 bg-gradient-to-r from-main-bg-colour"></div>
      <div className="absolute right-0 top-0 h-full w-96 bg-gradient-to-l from-main-bg-colour"></div>
    </div>
  );
};

export default Carousel;
